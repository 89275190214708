<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="彩种类型" prop="lotteryType">
        <el-input v-model="form.lotteryType" placeholder="请输入彩种类型" v-trim/>
      </el-form-item>
      <el-form-item label="投注位置" prop="locType">
        <el-input v-model="form.locType" placeholder="请输入投注位置" v-trim/>
      </el-form-item>
      <el-form-item label="投注模式" prop="bettingType">
        <el-input v-model="form.bettingType" placeholder="请输入投注模式" v-trim/>
      </el-form-item>
      <el-form-item label="投注倍数列表" prop="multiple">
        <el-input v-model="form.multiple" placeholder="请输入投注倍数列表" v-trim/>
      </el-form-item>
      <el-form-item label="当前倍数" prop="currentMultiple">
        <el-input v-model="form.currentMultiple" placeholder="请输入当前倍数" v-trim/>
      </el-form-item>
      <el-form-item label="投注规则" prop="rule">
        <el-input v-model="form.rule" placeholder="请输入投注规则" v-trim/>
      </el-form-item>
      <el-form-item label="是否启用" prop="enable">
        <el-input v-model="form.enable" placeholder="请输入是否启用" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaBettingConfigWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        lotteryType: '',
        locType: '',
        bettingType: '',
        multiple: '',
        currentMultiple: '',
        rule: '',
        enable: ''
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/bettingConfig',
      'field.id': 'id'
    })
  }
}
</script>
