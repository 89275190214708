<template>
  <TableLayout v-permissions="['system:interface:config:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="接口名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入接口名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['system:interface:config:create', 'system:interface:config:delete']">
        <li><el-button type="primary" @click="$refs.operaInterfaceConfigWindow.open('新建接口配置')" icon="el-icon-plus" v-permissions="['system:interface:config:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['system:interface:config:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="接口名称" min-width="100px"></el-table-column>
        <el-table-column prop="uriPattern" label="接口路径正则" min-width="100px"></el-table-column>
                <el-table-column prop="enableTwoFa" label="是否启用2FA认证" min-width="100px">
          <template slot-scope="{ row }">{{ row.enableTwoFa | enableText }}</template>
        </el-table-column>
        <el-table-column prop="enableEncrypt" label="是否启用请求加密" min-width="100px">
          <template slot-scope="{ row }">{{ row.enableEncrypt | enableText }}</template>
        </el-table-column>
        <el-table-column prop="weight" label="权重" min-width="100px"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px">
          <ColumnDetail slot-scope="{ row }" :content="row.remark" :limit="16"/>
        </el-table-column>
        <el-table-column prop="disabled" label="是否启用" min-width="100px">
          <template slot-scope="{row}">
            <el-switch v-model="row.disabled" :active-value="false" :inactive-value="true" @change="switchStatus(row)"/>
          </template>
        </el-table-column>
        <el-table-column prop="createUserInfo.realname" label="创建人" min-width="100px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="160px"></el-table-column>
        <el-table-column prop="updateUserInfo.realname" label="更新人" min-width="100px"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="160px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['system:interface:config:update', 'system:interface:config:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaInterfaceConfigWindow.open('编辑接口配置', row)" icon="el-icon-edit" v-permissions="['system:interface:config:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['system:interface:config:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :pagination="tableData.pagination"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaInterfaceConfigWindow ref="operaInterfaceConfigWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaInterfaceConfigWindow from '@/components/system/interfaceconfig/OperaInterfaceConfigWindow'
import ColumnDetail from '@/components/common/ColumnDetail'
export default {
  name: 'SystemInterfaceConfig',
  extends: BaseTable,
  components: { ColumnDetail, TableLayout, Pagination, OperaInterfaceConfigWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        name: ''
      }
    }
  },
  methods: {
    /**
     * 启用/禁用
     *
     * @param row 行对象
     */
    switchStatus (row) {
      const newValue = row.disabled
      row.disabled = !row.disabled
      // 开启
      if (!newValue) {
        this.__updateStatus(row, newValue)
        return
      }
      // 禁用
      this.$dialog.disableConfirm(`确认禁用 ${row.name} 接口配置吗？`)
        .then(() => {
          this.__updateStatus(row, newValue)
        })
        .catch(() => {
        })
    },
    /**
     * 修改状态
     *
     * @param row 行对象
     * @param newValue 新值
     * @private
     */
    __updateStatus (row, newValue) {
      this.api.updateStatus({
        id: row.id,
        disabled: newValue
      })
        .then(() => {
          row.disabled = newValue
          this.$tip.apiSuccess('修改成功')
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    }
  },
  created () {
    this.config({
      module: '接口配置',
      api: '/system/interfaceConfig',
      'field.id': 'id',
      'field.main': 'name'
    })
    this.search()
  }
}
</script>
