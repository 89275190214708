<template>
  <TableLayout :permissions="['bettingconfig:bettingconfig:query']">
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="主键" prop="id">
          <el-input v-model="searchForm.id" placeholder="请输入主键" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="彩种类型" prop="lotteryType">
          <el-input v-model="searchForm.lotteryType" placeholder="请输入彩种类型" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="投注位置" prop="locType">
          <el-input v-model="searchForm.locType" placeholder="请输入投注位置" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="投注模式" prop="bettingType">
          <el-input v-model="searchForm.bettingType" placeholder="请输入投注模式" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="投注倍数列表" prop="multiple">
          <el-input v-model="searchForm.multiple" placeholder="请输入投注倍数列表" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="当前倍数" prop="currentMultiple">
          <el-input v-model="searchForm.currentMultiple" placeholder="请输入当前倍数" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="投注规则" prop="rule">
          <el-input v-model="searchForm.rule" placeholder="请输入投注规则" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" prop="enable">
          <el-input v-model="searchForm.enable" placeholder="请输入是否启用" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['bettingconfig:bettingconfig:create', 'bettingconfig:bettingconfig:delete']">
        <li><el-button type="primary" @click="$refs.operaBettingConfigWindow.open('新建')" icon="el-icon-plus" v-permissions="['bettingconfig:bettingconfig:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['bettingconfig:bettingconfig:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="主键" min-width="100px"></el-table-column>
        <el-table-column prop="lotteryType" label="彩种类型" min-width="100px"></el-table-column>
        <el-table-column prop="locType" label="投注位置" min-width="100px"></el-table-column>
        <el-table-column prop="bettingType" label="投注模式" min-width="100px"></el-table-column>
        <el-table-column prop="multiple" label="投注倍数列表" min-width="100px"></el-table-column>
        <el-table-column prop="currentMultiple" label="当前倍数" min-width="100px"></el-table-column>
        <el-table-column prop="rule" label="投注规则" min-width="100px"></el-table-column>
        <el-table-column prop="enable" label="是否启用" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['bettingconfig:bettingconfig:update', 'bettingconfig:bettingconfig:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaBettingConfigWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['bettingconfig:bettingconfig:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['bettingconfig:bettingconfig:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaBettingConfigWindow ref="operaBettingConfigWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaBettingConfigWindow from '@/components/OperaBettingConfigWindow'
export default {
  name: 'BettingConfig',
  extends: BaseTable,
  components: { SearchFormCollapse, TableLayout, Pagination, OperaBettingConfigWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        lotteryType: '',
        locType: '',
        bettingType: '',
        multiple: '',
        currentMultiple: '',
        rule: '',
        enable: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/bettingConfig',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
