<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="接口名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入接口名称" v-trim/>
      </el-form-item>
      <el-form-item label="接口路径正则" prop="uriPattern" required>
        <el-input v-model="form.uriPattern" placeholder="请输入接口路径正则" v-trim/>
      </el-form-item>
            <el-form-item label="启用2FA认证" prop="enableTwoFa" class="form-item-switch" required>
        <el-switch v-model="form.enableTwoFa"/>
        <span class="switch-text">{{form.enableTwoFa | enableText}}</span>
        <FormItemTip>启用2FA认证后，接口将自动进行二次登录密码验证</FormItemTip>
      </el-form-item>
      <el-form-item label="启用请求加密" prop="enableEncrypt" class="form-item-switch" required>
        <el-switch v-model="form.enableEncrypt"/>
        <span class="switch-text">{{form.enableEncrypt | enableText}}</span>
        <FormItemTip>启用请求加密后，接口将自动解析请求参数和加密响应结果</FormItemTip>
      </el-form-item>
      <el-form-item label="权重" prop="weight" required>
        <el-input-number v-model="form.weight" placeholder="请输入权重" v-trim/>
        <FormItemTip>当请求匹配到多个接口设置时，以权重最高的配置为准</FormItemTip>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" v-model="form.remark" placeholder="请输入备注" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import FormItemTip from '@/components/common/FormItemTip'
export default {
  name: 'OperaSystemInterfaceConfigWindow',
  extends: BaseOpera,
  components: { FormItemTip, GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        uriPattern: '',
        enableTwoFa: false,
        enableEncrypt: false,
        weight: 0,
        remark: ''
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入接口名称' }
        ],
        uriPattern: [
          { required: true, message: '请输入接口路径正则' }
        ],
        disabled: [
          { required: true, message: '请输入是否禁用' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/system/interfaceConfig',
      'field.id': 'id'
    })
  }
}
</script>
