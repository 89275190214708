<template>
  <TableLayout>
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="主键" prop="id">
          <el-input v-model="searchForm.id" placeholder="请输入主键" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="彩种类型" prop="lotteryType">
          <el-input v-model="searchForm.lotteryType" placeholder="请输入彩种类型" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="投注位置" prop="locType">
          <el-input v-model="searchForm.locType" placeholder="请输入投注位置" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="投注模式" prop="bettingType">
          <el-input v-model="searchForm.bettingType" placeholder="请输入投注模式" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="投注倍数" prop="multiple">
          <el-input v-model="searchForm.multiple" placeholder="请输入投注倍数" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="投注金额" prop="amount">
          <el-input v-model="searchForm.amount" placeholder="请输入投注金额" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="中奖金额" prop="wining">
          <el-input v-model="searchForm.wining" placeholder="请输入中奖金额" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="投注期号" prop="issue">
          <el-input v-model="searchForm.issue" placeholder="请输入投注期号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="开奖号码" prop="numbers">
          <el-input v-model="searchForm.numbers" placeholder="请输入开奖号码" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar">
        <li><el-button type="primary" @click="$refs.operaBettingLogWindow.open('新建')" icon="el-icon-plus">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="主键" min-width="100px"></el-table-column>
        <el-table-column prop="lotteryType" label="彩种类型" min-width="100px"></el-table-column>
        <el-table-column prop="locType" label="投注位置" min-width="100px"></el-table-column>
        <el-table-column prop="bettingType" label="投注模式" min-width="100px"></el-table-column>
        <el-table-column prop="multiple" label="投注倍数" min-width="100px"></el-table-column>
        <el-table-column prop="amount" label="投注金额" min-width="100px"></el-table-column>
        <el-table-column prop="wining" label="中奖金额" min-width="100px"></el-table-column>
        <el-table-column prop="issue" label="投注期号" min-width="100px"></el-table-column>
        <el-table-column prop="numbers" label="开奖号码" min-width="100px"></el-table-column>
        <el-table-column
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaBettingLogWindow.open('编辑', row)" icon="el-icon-edit">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaBettingLogWindow ref="operaBettingLogWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaBettingLogWindow from '@/components/OperaBettingLogWindow'
export default {
  name: 'BettingLog',
  extends: BaseTable,
  components: { SearchFormCollapse, TableLayout, Pagination, OperaBettingLogWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        lotteryType: '',
        locType: '',
        bettingType: '',
        multiple: '',
        amount: '',
        wining: '',
        issue: '',
        numbers: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/bettingLog',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
