<template>
  <div class="loading">
    <i class="el-icon-loading"></i>
    <slot><p>加载中...</p></slot>
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style scoped lang="scss">
.loading {
  padding: 40px 0;
  text-align: center;
  i {
    font-size: 20px;
  }
  p {
    color: #999;
    margin: 0;
    font-size: 13px;
  }
}
</style>
