<template>
  <TableLayout>
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="主键" prop="id">
          <el-input v-model="searchForm.id" placeholder="请输入主键" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="访问地址" prop="url">
          <el-input v-model="searchForm.url" placeholder="请输入访问地址" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="认证头" prop="authorization">
          <el-input v-model="searchForm.authorization" placeholder="请输入认证头" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="Cookies" prop="cookie">
          <el-input v-model="searchForm.cookie" placeholder="请输入Cookies" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar">
        <li><el-button type="primary" @click="$refs.operaXyLoginWindow.open('新建')" icon="el-icon-plus">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="主键" min-width="100px"></el-table-column>
        <el-table-column prop="url" label="访问地址" min-width="100px"></el-table-column>
        <el-table-column prop="authorization" label="认证头" min-width="100px"></el-table-column>
        <el-table-column prop="cookie" label="Cookies" min-width="100px"></el-table-column>
        <el-table-column
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaXyLoginWindow.open('编辑', row)" icon="el-icon-edit">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaXyLoginWindow ref="operaXyLoginWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaXyLoginWindow from '@/components/OperaXyLoginWindow'
export default {
  name: 'XyLogin',
  extends: BaseTable,
  components: { SearchFormCollapse, TableLayout, Pagination, OperaXyLoginWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        url: '',
        authorization: '',
        cookie: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/xyLogin',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
