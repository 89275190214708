import axios from 'axios'
import Cookies from 'js-cookie'
import pkg from '../../../package'
import { trim } from '@/utils/util'
import cache from '@/plugins/cache'
import extendsMethods from './extends.methods'
import AES from '@/utils/aes'

// 默认配置
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_PREFIX,
  // 请求超时时间
  timeout: 60000
})

// 新建请求拦截器
axiosInstance.interceptors.request.use(config => {
  // 初始化加密标识值
  config.encrypt = config.encrypt == null ? process.env.VUE_APP_ENCRYPT_REQUEST === 'on' : config.encrypt
  if (config.headers['Content-Type'] != null && config.headers['Content-Type'].startsWith('multipart/form-data')) {
    config.encrypt = false
  }
  // 参数去空格
  if (config.trim === true) {
    if (config.data != null) {
      config.data = trim(config.data)
    }
    if (config.params != null) {
      config.params = trim(config.params)
    }
  }
  // 导出处理
  if (config.download === true) {
    config.responseType = 'blob'
  }
  // 设置操作平台
  config.headers['eva-platform'] = `pc-${pkg.version}`
  // 设置2fa密码
  const cachePwd = cache.twoFA.__get('eva-2fa-password')
  if (cachePwd != null) {
    config.headers['eva-2fa-password'] = cachePwd.value
    // 未记住密码时直接删除密码
    if (cachePwd.expiredTime == null) {
      // 延迟删除，防止promise.all同时发起多个2FA接口时无法读取到密码
      setTimeout(() => {
        cache.twoFA.removePassword()
      }, 200)
    }
  }
  // 参数加密
  if (config.encrypt) {
    let requestData = null
    if (config.data != null) {
      requestData = config.data
    }
    if (config.params != null) {
      requestData = config.params
    }
    if (process.env.VUE_APP_DEBUG === 'on') {
      console.log(`DEBUG: ${config.method} ${config.url}`, '请求参数', requestData)
    }
    // POST请求
    if (config.data != null) {
      config.data = {
        _p: AES.encrypt(JSON.stringify(config.data))
      }
    }
    // GET请求
    if (config.params != null) {
      config.params = {
        _p: AES.encrypt(JSON.stringify(config.params))
      }
    }
  }
  // 设置认证头
  const authToken = Cookies.get('eva-auth-token')
  if (authToken != null) {
    config.headers['eva-auth-token'] = authToken
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// 新建响应拦截器
axiosInstance.interceptors.response.use((response) => {
  // 请求失败
  if (response.status !== 200) {
    return Promise.reject(new Error('服务器繁忙，请稍后再试'))
  }
  // 下载接口处理
  if (response.headers['eva-opera-type'] === 'download') {
    return Promise.resolve(response)
  }
  // 解密响应结果
  if (typeof response.data === 'string') {
    response.data = JSON.parse(AES.decrypt(response.data))
    if (process.env.VUE_APP_DEBUG === 'on') {
      console.log(`DEBUG: ${response.config.method} ${response.config.url}`, '请求结果', JSON.parse(JSON.stringify(response)))
    }
  }
  // 未登录
  if (response.data.code === 401) {
    if (response.config.autoLogin !== false) {
      window.location.href = process.env.VUE_APP_ROUTER_MODE === 'history' ? '/#/login' : '/login'
    }
    return Promise.reject(response.data)
  }
  // 业务失败
  if (!response.data.success) {
    return Promise.reject(response.data)
  }
  return response.data.data
}, function (error) {
  console.log('error', error)
  if (error.code == null) {
    return Promise.reject(new Error('服务器繁忙，请稍后再试'))
  }
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    return Promise.reject(new Error('服务器响应超时，请稍后再试'))
  }
  return Promise.reject(error)
})

// 添加扩展方法
for (const key in extendsMethods) {
  axiosInstance[key] = extendsMethods[key]
}

export default axiosInstance
