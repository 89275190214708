<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="期号" prop="issue" required>
        <el-input v-model="form.issue" placeholder="请输入期号" v-trim/>
      </el-form-item>
      <el-form-item label="开奖号码" prop="numbers" required>
        <el-input v-model="form.numbers" placeholder="请输入开奖号码" v-trim/>
      </el-form-item>
      <el-form-item label="彩种类型" prop="type">
        <el-input v-model="form.type" placeholder="请输入彩种类型" v-trim/>
      </el-form-item>
      <el-form-item label="规则名称" prop="rule">
        <el-input v-model="form.rule" placeholder="请输入规则名称" v-trim/>
      </el-form-item>
      <el-form-item label="匹配长度" prop="length">
        <el-input v-model="form.length" placeholder="请输入匹配长度" v-trim/>
      </el-form-item>
      <el-form-item label="匹配成功次数" prop="totalCount">
        <el-input v-model="form.totalCount" placeholder="请输入匹配成功次数" v-trim/>
      </el-form-item>
      <el-form-item label="匹配正确次数" prop="matchCount">
        <el-input v-model="form.matchCount" placeholder="请输入匹配正确次数" v-trim/>
      </el-form-item>
      <el-form-item label="历史概率" prop="matchRate">
        <el-input v-model="form.matchRate" placeholder="请输入历史概率" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaAnalysisLogWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        issue: '',
        numbers: '',
        type: '',
        rule: '',
        length: '',
        totalCount: '',
        matchCount: '',
        matchRate: ''
      },
      // 验证规则
      rules: {
        issue: [
          { required: true, message: '请输入期号' }
        ],
        numbers: [
          { required: true, message: '请输入开奖号码' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/analysisLog',
      'field.id': 'id'
    })
  }
}
</script>
