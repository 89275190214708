import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/user/message/page', data, {
    trim: true
  })
}

// 通知完成
export function notifyComplete (data) {
  return request.post('/system/user/message/notifyComplete', data)
}

// 修改
export function read (ids) {
  return request.get('/system/user/message/read', {
    params: {
      ids
    }
  })
}
