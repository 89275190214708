<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="彩种类型" prop="lotteryType">
        <el-input v-model="form.lotteryType" placeholder="请输入彩种类型" v-trim/>
      </el-form-item>
      <el-form-item label="投注位置" prop="locType">
        <el-input v-model="form.locType" placeholder="请输入投注位置" v-trim/>
      </el-form-item>
      <el-form-item label="投注模式" prop="bettingType">
        <el-input v-model="form.bettingType" placeholder="请输入投注模式" v-trim/>
      </el-form-item>
      <el-form-item label="投注倍数" prop="multiple">
        <el-input v-model="form.multiple" placeholder="请输入投注倍数" v-trim/>
      </el-form-item>
      <el-form-item label="投注金额" prop="amount">
        <el-input v-model="form.amount" placeholder="请输入投注金额" v-trim/>
      </el-form-item>
      <el-form-item label="中奖金额" prop="wining">
        <el-input v-model="form.wining" placeholder="请输入中奖金额" v-trim/>
      </el-form-item>
      <el-form-item label="投注期号" prop="issue" required>
        <el-input v-model="form.issue" placeholder="请输入投注期号" v-trim/>
      </el-form-item>
      <el-form-item label="开奖号码" prop="numbers">
        <el-input v-model="form.numbers" placeholder="请输入开奖号码" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaBettingLogWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        lotteryType: '',
        locType: '',
        bettingType: '',
        multiple: '',
        amount: '',
        wining: '',
        issue: '',
        numbers: ''
      },
      // 验证规则
      rules: {
        issue: [
          { required: true, message: '请输入投注期号' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/bettingLog',
      'field.id': 'id'
    })
  }
}
</script>
