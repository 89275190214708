<template>
  <TableLayout :permissions="['hnssc2:hnssc2:query']">
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="主键" prop="id">
          <el-input v-model="searchForm.id" placeholder="请输入主键" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="期号" prop="issue">
          <el-input v-model="searchForm.issue" placeholder="请输入期号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="开奖号码" prop="numbers">
          <el-input v-model="searchForm.numbers" placeholder="请输入开奖号码" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="开奖号码-个位" prop="one">
          <el-input v-model="searchForm.one" placeholder="请输入开奖号码-个位" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="开奖号码-十位" prop="ten">
          <el-input v-model="searchForm.ten" placeholder="请输入开奖号码-十位" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="开奖号码-百位" prop="hundred">
          <el-input v-model="searchForm.hundred" placeholder="请输入开奖号码-百位" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="开奖号码-千位" prop="thousand">
          <el-input v-model="searchForm.thousand" placeholder="请输入开奖号码-千位" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="开奖号码-万位" prop="tenThousand">
          <el-input v-model="searchForm.tenThousand" placeholder="请输入开奖号码-万位" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="创建记录时间" prop="createDate">
          <el-date-picker v-model="searchForm.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建记录时间" @change="search"/>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['hnssc2:hnssc2:create', 'hnssc2:hnssc2:delete']">
        <li><el-button type="primary" @click="$refs.operaHnSsc2Window.open('新建')" icon="el-icon-plus" v-permissions="['hnssc2:hnssc2:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['hnssc2:hnssc2:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="主键" min-width="100px"></el-table-column>
        <el-table-column prop="issue" label="期号" min-width="100px"></el-table-column>
        <el-table-column prop="numbers" label="开奖号码" min-width="100px"></el-table-column>
        <el-table-column prop="one" label="开奖号码-个位" min-width="100px"></el-table-column>
        <el-table-column prop="ten" label="开奖号码-十位" min-width="100px"></el-table-column>
        <el-table-column prop="hundred" label="开奖号码-百位" min-width="100px"></el-table-column>
        <el-table-column prop="thousand" label="开奖号码-千位" min-width="100px"></el-table-column>
        <el-table-column prop="tenThousand" label="开奖号码-万位" min-width="100px"></el-table-column>
        <el-table-column prop="createDate" label="创建记录时间" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['hnssc2:hnssc2:update', 'hnssc2:hnssc2:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaHnSsc2Window.open('编辑', row)" icon="el-icon-edit" v-permissions="['hnssc2:hnssc2:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['hnssc2:hnssc2:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaHnSsc2Window ref="operaHnSsc2Window" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaHnSsc2Window from '@/components/OperaHnSsc2Window'
export default {
  name: 'HnSsc2',
  extends: BaseTable,
  components: { SearchFormCollapse, TableLayout, Pagination, OperaHnSsc2Window },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        issue: '',
        numbers: '',
        one: '',
        ten: '',
        hundred: '',
        thousand: '',
        tenThousand: '',
        createDate: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/hnSsc2',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
