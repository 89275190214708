<template>
  <TableLayout :permissions="['analysislog:analysislog:query']">
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="主键" prop="id">
          <el-input v-model="searchForm.id" placeholder="请输入主键" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="期号" prop="issue">
          <el-input v-model="searchForm.issue" placeholder="请输入期号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="开奖号码" prop="numbers">
          <el-input v-model="searchForm.numbers" placeholder="请输入开奖号码" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="彩种类型" prop="type">
          <el-input v-model="searchForm.type" placeholder="请输入彩种类型" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="规则名称" prop="rule">
          <el-input v-model="searchForm.rule" placeholder="请输入规则名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="匹配长度" prop="length">
          <el-input v-model="searchForm.length" placeholder="请输入匹配长度" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="匹配成功次数" prop="totalCount">
          <el-input v-model="searchForm.totalCount" placeholder="请输入匹配成功次数" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="匹配正确次数" prop="matchCount">
          <el-input v-model="searchForm.matchCount" placeholder="请输入匹配正确次数" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="历史概率" prop="matchRate">
          <el-input v-model="searchForm.matchRate" placeholder="请输入历史概率" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['analysislog:analysislog:create', 'analysislog:analysislog:delete']">
        <li><el-button type="primary" @click="$refs.operaAnalysisLogWindow.open('新建')" icon="el-icon-plus" v-permissions="['analysislog:analysislog:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['analysislog:analysislog:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="主键" min-width="100px"></el-table-column>
        <el-table-column prop="issue" label="期号" min-width="100px"></el-table-column>
        <el-table-column prop="numbers" label="开奖号码" min-width="100px"></el-table-column>
        <el-table-column prop="type" label="彩种类型" min-width="100px"></el-table-column>
        <el-table-column prop="rule" label="规则名称" min-width="100px"></el-table-column>
        <el-table-column prop="length" label="匹配长度" min-width="100px"></el-table-column>
        <el-table-column prop="totalCount" label="匹配成功次数" min-width="100px"></el-table-column>
        <el-table-column prop="matchCount" label="匹配正确次数" min-width="100px"></el-table-column>
        <el-table-column prop="matchRate" label="历史概率" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['analysislog:analysislog:update', 'analysislog:analysislog:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaAnalysisLogWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['analysislog:analysislog:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['analysislog:analysislog:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaAnalysisLogWindow ref="operaAnalysisLogWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaAnalysisLogWindow from '@/components/OperaAnalysisLogWindow'
export default {
  name: 'AnalysisLog',
  extends: BaseTable,
  components: { SearchFormCollapse, TableLayout, Pagination, OperaAnalysisLogWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        issue: '',
        numbers: '',
        type: '',
        rule: '',
        length: '',
        totalCount: '',
        matchCount: '',
        matchRate: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/analysisLog',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
