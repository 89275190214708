<template>
  <GlobalWindow
    title="我的消息"
    width="800px"
    :visible="message.visible"
    :with-footer="false"
    class="user-message-window"
    @confirm="openUserMessageWindow()"
    @update:visible="closeUserMessageWindow()"
  >
    <div class="body">
      <el-tabs v-model="activeTab" @tab-click="changeTab">
        <el-tab-pane v-for="tab in tabs" :label="tab.label" :name="tab.name" :key="tab.name">
          <!-- 加载中 -->
          <Loading v-if="isWorking.search"/>
          <!-- 没有消息 -->
          <EmptyTip v-else-if="tableData.list.length === 0"/>
          <!-- 消息列表 -->
          <ul class="message-list" v-else>
            <li v-for="message in tableData.list" :key="message.id">
              <h2>{{message.title}}</h2>
              <p>{{message.content}}</p>
              <div class="opera">
                <span>{{message.createTime}}</span>
                <div v-if="message.businessOperaType != null">
                  <el-button v-if="message.unread" type="text" @click="doRead('' + message.id)">标为已读</el-button>
                  <el-button v-if="message.businessOperaType != null && message.businessOperaType !== ''" type="primary" size="mini" @click="doBusinessOpera(message)">{{message | operaButtonText}}</el-button>
                </div>
              </div>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
      <div class="toolbar">
        <el-input
          v-model="keyword"
          class="search-input"
          suffix-icon="el-icon-search"
          placeholder="关键字，回车搜索"
          @keydown.enter.native="fetchList"
        />
        <el-button v-if="activeTab === 'unread' && tableData.list.length > 0" type="text" @click="doReadAll">当前标为已读</el-button>
      </div>
      <Pagination
        :pagination="tableData.pagination"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      />
    </div>
  </GlobalWindow>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import GlobalWindow from '@/components/common/GlobalWindow'
import Pagination from '@/components/common/Pagination'
import Loading from '@/components/common/Loading'
import EmptyTip from '@/components/common/EmptyTip'
import { fetchList, read } from '@/api/system/userMessage'
import { downloadLocalFile } from '@/api/system/common'

export default {
  name: 'UserMessageWindow',
  components: { EmptyTip, Loading, Pagination, GlobalWindow },
  data () {
    return {
      visible: false,
      // 当前tab
      activeTab: 'unread',
      // tabs
      tabs: [
        { name: 'unread', label: '未读' },
        { name: 'read', label: '已读' }
      ],
      // 是否正在执行
      isWorking: {
        // 搜索中
        search: false
      },
      // 搜索关键字
      keyword: '',
      // 表格数据
      tableData: {
        // 当前页数据
        list: [],
        // 分页
        pagination: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        }
      }
    }
  },
  computed: {
    ...mapState(['message'])
  },
  watch: {
    'message.visible': function (newValue) {
      if (newValue) {
        this.fetchList()
      }
    }
  },
  filters: {
    // 操作按钮文案
    operaButtonText (message) {
      if (message.businessOperaType === 'check') {
        return '点击查看'
      }
      if (message.businessOperaType.startsWith('download')) {
        return '点击下载'
      }
    }
  },
  methods: {
    ...mapMutations(['openUserMessageWindow', 'closeUserMessageWindow']),
    open () {
      this.activeTab = 'unread'
      this.tableData.pagination.pageIndex = 1
      this.tableData.pagination.pageSize = 10
      this.openUserMessageWindow()
    },
    /**
     * 切换Tab
     */
    changeTab (tab) {
      this.tableData.pagination.pageIndex = 1
      this.fetchList(tab.name === 'unread')
    },
    /**
     * 获取消息列表
     */
    fetchList () {
      this.isWorking.search = true
      fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: {
          unread: this.activeTab === 'unread',
          keyword: this.keyword
        },
        sorts: []
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    /**
     * 页容量变更处理（切换页容量时触发）
     *
     * @param pageSize 页容量
     */
    handleSizeChange (pageSize) {
      this.tableData.pagination.pageSize = pageSize
      this.fetchList()
    },
    /**
     * 页码变更处理（分页时触发）
     *
     * @param pageIndex 新页码
     */
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex
      this.fetchList()
    },
    /**
     * 标记为已读
     */
    doRead (ids) {
      // 已读标签无需标记为已读
      if (this.activeTab === 'read') {
        return
      }
      read(ids)
        .then(() => {
          this.$tip.apiSuccess('标为已读成功')
          if (this.tableData.list.length === ids.split(',').length) {
            this.tableData.pagination.pageIndex--
            if (this.tableData.pagination.pageIndex === 0) {
              this.tableData.pagination.pageIndex = 1
            }
          }
          this.fetchList()
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    /**
     * 当前标记为已读
     */
    doReadAll () {
      this.doRead(this.tableData.list.map(item => item.id).join(','))
    },
    /**
     * 处理业务操作
     */
    doBusinessOpera (message) {
      // 查看
      if (message.businessOperaType === 'check') {
        this.closeUserMessageWindow()
        this.$router.push(message.businessUri)
          .catch(() => {})
      }
      // 下载本地文件
      if (message.businessOperaType === 'download_local') {
        downloadLocalFile(JSON.parse(message.businessParam))
          .then(res => {
            this.download(res)
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.user-message-window {
  /deep/ .window__body {
    padding: 0 10px !important;
  }
  .body {
    height: 100%;
    display: flex;
    flex-direction: column;
    /deep/ .el-tabs {
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .el-tabs__header {
        flex-shrink: 0;
        height: 40px;
        margin: 0;
      }
      .el-tabs__content {
        flex-grow: 1;
                overflow-y: auto;
      }
    }
    .table-pagination {
      border-top: 1px solid #eee;
      flex-shrink: 0;
      height: 50px;
      display: flex;
      align-items: center;
    }
  }
  // 工具栏
  .toolbar {
    position: absolute;
    right: 10px;
    top: 4px;
    font-size: 13px;
    // 搜索框
    /deep/ .search-input {
      width: 160px;
      .el-input__inner{
        border-radius: 20px;
      }
    }
    .el-button {
      margin-left: 12px;
    }
  }
  // 消息列表
  .message-list {
    li {
      padding: 16px 10px 16px 0;
      border-bottom: 1px solid #eee;
      h2 {
        font-size: 16px;
        font-weight: normal;
      }
      p {
        font-size: 14px;
        color: #555;
        margin: 0;
        padding: 12px 0 0 0;
      }
      .opera {
        display: flex;
        font-size: 13px;
        color: #999;
        span {
          flex-shrink: 0;
          width: 200px;
          line-height: 30px;
        }
        & > div {
          flex-grow: 1;
          text-align: right;
        }
      }
    }
  }
}
</style>
