<template>
  <div class="empty-tip">
    <slot>暂无数据</slot>
  </div>
</template>

<script>
export default {
  name: 'EmptyTip'
}
</script>

<style scoped lang="scss">
.empty-tip {
  text-align: center;
  padding: 40px 0;
  color: #999;
}
</style>
