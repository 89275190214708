<template>
  <el-dialog
    :width="width"
    :title="title"
    :visible.sync="visible"
    append-to-body
    custom-class="eva-dialog two-fa-window"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <slot name="beforeForm"><p class="two-fa-window__message">{{message}}</p></slot>
    <el-form ref="form" :model="form" :rules="rules" inline>
      <el-form-item label="登录密码" prop="password" label-width="85px" required>
        <PasswordInput v-model="form.password"/>
      </el-form-item>
    </el-form>
    <slot name="afterForm"></slot>
    <div slot="footer" class="two-fa-window__footer">
      <div class="remember-pwd">
        <el-checkbox v-model="rememberPwd"/><span>记住密码</span>
      </div>
      <div class="opera">
        <el-button @click="cancel">{{cancelText}}</el-button>
        <el-button type="primary" @click="confirm" :loading="isWorking">{{confirmText}}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import PasswordInput from './PasswordInput'
export default {
  name: 'TwoFAWindow',
  components: { PasswordInput },
  props: {
    // 标题
    title: {
      type: String,
      default: '安全验证'
    },
    // 消息内容
    message: {
      type: String,
      required: false
    },
    // 确认按钮文案
    confirmText: {
      type: String,
      default: '确定'
    },
    // 取消按钮文案
    cancelText: {
      type: String,
      default: '取消'
    },
    // 宽度
    width: {
      type: String,
      default: '400px'
    }
  },
  data () {
    return {
      visible: false,
      isWorking: false,
      // 记住密码
      rememberPwd: false,
      // 请求对象
      request: null,
      // 请求方式
      method: null,
      // 请求参数
      requestArguments: null,
      // 表单
      form: {
        password: ''
      },
      // 表单验证规则
      rules: {
        password: { required: true, message: '请输入登录密码' }
      }
    }
  },
  methods: {
    /**
     * 打开窗口（通常情况下由2FA机制自动调用）
     *
     * @param axiosInstance axios实例
     * @param method 请求方式
     * @param requestArguments 请求参数
     */
    open (axiosInstance, method, requestArguments) {
      this.request = axiosInstance
      this.method = method
      this.requestArguments = requestArguments
      this.visible = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /**
     * 确定
     */
    confirm () {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }
        // 写入密码
        this.$cache.twoFA.setPassword(this.form.password, this.rememberPwd)
        // 接口2fa调用
        if (this.request != null) {
          this.isWorking = true
          this.request[this.method].apply(this.request, this.requestArguments)
            .then(data => {
              this.$emit('then', data)
              // 销毁窗口
              this.close()
            })
            .catch(e => {
              this.$emit('catch', e)
            })
            .finally(() => {
              this.isWorking = false
            })
          return
        }
        // 手动调用
        this.$emit('confirm', this.form)
      })
    },
    /**
     * 取消
     */
    cancel () {
      // 销毁窗口
      this.close()
      // 接口2fa调用
      if (this.request != null) {
        this.$emit('cancel', new Error('#ignore#: cancel 2fa'))
        return
      }
      // 手动调用
      this.$emit('cancel')
    },
    /**
     * 关闭窗口
     */
    close () {
      this.visible = false
      setTimeout(() => {
        document.body.removeChild(this.$el)
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
.two-fa-window {
  // 提示消息
  .two-fa-window__message {
    margin: 0 0 10px 0;
  }
  // 底部操作
  .two-fa-window__footer {
    display: flex;
    .remember-pwd {
      width: 100px;
      flex-shrink: 0;
      text-align: left;
      font-size: 13px;
      display: flex;
      align-items: center;
      .el-checkbox {
        margin-right: 10px;
      }
      & > * {
        vertical-align: middle;
      }
    }
    .opera {
      flex-grow: 1;
    }
  }
}
</style>
