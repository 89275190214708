<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="期号" prop="issue" required>
        <el-input v-model="form.issue" placeholder="请输入期号" v-trim/>
      </el-form-item>
      <el-form-item label="开奖号码" prop="numbers" required>
        <el-input v-model="form.numbers" placeholder="请输入开奖号码" v-trim/>
      </el-form-item>
      <el-form-item label="开奖号码-个位" prop="one" required>
        <el-input v-model="form.one" placeholder="请输入开奖号码-个位" v-trim/>
      </el-form-item>
      <el-form-item label="开奖号码-十位" prop="ten" required>
        <el-input v-model="form.ten" placeholder="请输入开奖号码-十位" v-trim/>
      </el-form-item>
      <el-form-item label="开奖号码-百位" prop="hundred" required>
        <el-input v-model="form.hundred" placeholder="请输入开奖号码-百位" v-trim/>
      </el-form-item>
      <el-form-item label="开奖号码-千位" prop="thousand" required>
        <el-input v-model="form.thousand" placeholder="请输入开奖号码-千位" v-trim/>
      </el-form-item>
      <el-form-item label="开奖号码-万位" prop="tenThousand" required>
        <el-input v-model="form.tenThousand" placeholder="请输入开奖号码-万位" v-trim/>
      </el-form-item>
      <el-form-item label="创建记录时间" prop="createDate" required>
        <el-date-picker v-model="form.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建记录时间"></el-date-picker>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaHnSsc2Window',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        issue: '',
        numbers: '',
        one: '',
        ten: '',
        hundred: '',
        thousand: '',
        tenThousand: '',
        createDate: ''
      },
      // 验证规则
      rules: {
        issue: [
          { required: true, message: '请输入期号' }
        ],
        numbers: [
          { required: true, message: '请输入开奖号码' }
        ],
        one: [
          { required: true, message: '请输入开奖号码-个位' }
        ],
        ten: [
          { required: true, message: '请输入开奖号码-十位' }
        ],
        hundred: [
          { required: true, message: '请输入开奖号码-百位' }
        ],
        thousand: [
          { required: true, message: '请输入开奖号码-千位' }
        ],
        tenThousand: [
          { required: true, message: '请输入开奖号码-万位' }
        ],
        createDate: [
          { required: true, message: '请选择创建记录时间' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/hnSsc2',
      'field.id': 'id'
    })
  }
}
</script>
