<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="访问地址" prop="url">
        <el-input v-model="form.url" placeholder="请输入访问地址" v-trim/>
      </el-form-item>
      <el-form-item label="认证头" prop="authorization">
        <el-input v-model="form.authorization" placeholder="请输入认证头" v-trim/>
      </el-form-item>
      <el-form-item label="Cookies" prop="cookie">
        <el-input v-model="form.cookie" placeholder="请输入Cookies" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaXyLoginWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        url: '',
        authorization: '',
        cookie: ''
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/xyLogin',
      'field.id': 'id'
    })
  }
}
</script>
